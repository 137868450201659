import { isNullUndefinedEmpty } from '../helpers/helpers';

export const component = async () => {
    const momentModule = await import('moment-mini');
    window.moment = momentModule.default;

    EventsCalendar.init();
};

let s;
let EventsCalendar = EventsCalendar || {};

EventsCalendar = {
    settings: {
        name: 'equ-eventscalendar',
        $container: $('[data-equ-eventscalendar]'),
        $btnPrev: null,
        $btnNext: null,
        today: new Date(),
        yearsBeforeToRender: 1,
        yearsAheadToRender: 1, //Not in use for Dfes...
    },

    init() {
        s = this.settings;
        const search = window.location.search;

        if (s.$container.length) {
            try {
                var yearAgo = new Date();
                yearAgo.setFullYear(yearAgo.getFullYear() - s.yearsBeforeToRender);
                yearAgo.setDate(1);
                var yearFromNow = new Date();
                yearFromNow.setFullYear(yearFromNow.getFullYear() + s.yearsAheadToRender);
                yearFromNow.setDate(1);

                this.generate();
                this.fetchData(yearAgo, yearFromNow);
                this.setupInteractions();

                const $eventWrapper = $('.events-page-wrapper');

                $('.js-view-toggle').on('click', function () {
                    const $this = $(this);
                    $this.siblings().removeClass('is-active');
                    $this.addClass('is-active');

                    if ($(this).hasClass('-calendar')) {
                        //list view
                        $('.events_container, .search-main-container').hide();
                        //calendar view
                        $('.events-calendar').show();

                        if ($eventWrapper.hasClass('has-searchquery')) {
                            $eventWrapper.show();
                            $('.search-results-events').hide();
                        }
                    } else {
                        //list view
                        $('.search-main-container').show();
                        //calendar view
                        $('.events-calendar').hide();
                        if ($eventWrapper.hasClass('has-searchquery')) {
                            $eventWrapper.show();
                            $('.search-results-events').show();
                        } else {
                            $('.events_container').show();
                        }
                    }
                });
            } catch (error) {
                console.error('There was an error generating the calendar: ', error);
            }
        }
    },

    fetchData(fromDate, toDate) {
        //Get the Events from the default sitefinity calendar API,
        //then get summary data from a custom Sitefinity API
        //These can't be combined as the default API is difficult to customise
        //but the custom one does not provide recurring events
        const that = this;

        var apiUrl = s.$container.data('sf-controller-events');
        var calendarApiUrl = window.location.origin + s.$container.data('sf-controller-calendars');

        var data = {
            StartDate: fromDate.toISOString(),
            EndDate: toDate.toISOString(),
            Id: 'c55a5b00-40ee-404a-b58c-b5e0b18b682c', //Hard coded to main calendar. Requires additional API call to calendarApiUrl to handle multiple calendars
        };

        getEventData();

        function getEventData() {
            $.ajax({
                type: 'GET',
                url: apiUrl,
                data: data,
                dataType: 'JSON',
                success: function (response, status) {
                    console.log(response);

                    if (response.length > 0) {
                        getSummaryData(response);
                    }
                },
                error: function (data) {
                    console.error('Calendar event API call failed.');
                },
            });
        }
        //Now get summaries and match them by Id
        function getSummaryData(eventData, nextLink, eventsWithoutSummary) {
            eventsWithoutSummary = eventsWithoutSummary || eventData;
            
            $.ajax({
                type: 'GET',
                // if nextLink is null, get the first 50 events by api call
                url: nextLink ? nextLink : '/api/public/events?$select=Title,Summary,UrlName',
                dataType: 'JSON',
                success: function (response, status) {
                    console.log('Summary data returned: ', response);
                    const eventsSummaries = response.value;

                    //Populate Event data with summaries
                    if (eventsSummaries.length > 0) {
                        eventsWithoutSummary = eventsWithoutSummary.filter((event) => {
                            //Match with Url name, as the Ids do not match
                            const eventUrlName = event.EventUrl.split('/').pop();
                            var matchingEventSummary = eventsSummaries.filter((summaryItem) => summaryItem.UrlName === eventUrlName)[0];

                            if (!isNullUndefinedEmpty(matchingEventSummary)) {
                                event.Summary = matchingEventSummary.Summary;
                                return false; // Remove this event from eventsWithoutSummary
                            }
                            return true; // Keep this event in eventsWithoutSummary
                        });
                    }
                    
                    // Check if there are still events without summaries and if there's a next link
                    var nextLink = response['@odata.nextLink'];
                    if (eventsWithoutSummary.length > 0 && nextLink) {
                        //check if window.location.origin is https and handle for http in nextLink
                        if (window.location.origin.startsWith('https')) {
                            nextLink = nextLink.replace('http', 'https');
                        }
                        //strip the first section from nextLink
                        nextLink = nextLink.replace(window.location.origin, '');
                        getSummaryData(eventData, nextLink, eventsWithoutSummary);
                    } else {
                        // We've either found all summaries or there are no more pages to check
                        eventsWithoutSummary.forEach(event => {
                            if (isNullUndefinedEmpty(event.Summary)) {
                                console.warn(`A summary could not be found for the event id: ${event.Id}`);
                            }
                        });
                        
                        console.log('Events with summaries: ', eventData);
                        that.populateEvents(eventData);
                    }
                },
                error: function (data) {
                    console.error('Custom event API call failed.');
                },
            });
        }
    },

    getEventHtml(event) {
        const eventFullUrl = window.location.href + '/event-details' + event.EventUrl;
        return `
            <li>
                <a data-event-id="${event.Id}" href="${eventFullUrl}" title="${event.Title}" data-title="${event.Title}" data-description="${event.Summary ?? ''}">${event.Title}</a>
            </li>
        `;
    },

    addEventToDay(day, event) {
        const $dayLi = $(`[data-calendar-date="${day.format('D/M/YYYY')}"]`);

        if ($dayLi.find('ul').length === 0) {
            //No events exist yet, add required UL
            const ul = $('<ul class="day__events">');

            ul.append(this.getEventHtml(event));
            $dayLi.append(ul);
        } else {
            $dayLi.find('ul').append(this.getEventHtml(event));
        }
    },

    populateEvents(data) {
        data.forEach((event) => {
            var startDate = moment.utc(event.Start).local();
            var endDate = moment.utc(event.End).local();

            //Repeating event as the end date is after the start date.
            if (endDate.isAfter(startDate, 'day')) {
                console.log(
                    `Event ${event.Title} must span multiple days between ${startDate.format('D/M/YYYY')} and ${endDate.format('D/M/YYYY')}`
                );
                //Keep adding the event to days until we get the end date
                var currentDate = moment.utc(event.Start).local();

                while (!endDate.isBefore(currentDate, 'day')) {
                    this.addEventToDay(currentDate, event);
                    currentDate.add(1, 'days');
                }
            } else {
                this.addEventToDay(startDate, event);
            }
        });

        //Add buttons where needed
        $('.day__events').each(function () {
            if ($(this).find('li').length > 2) {
                console.log('Found more than 2 events in one day');
                $(this).after(`
                    <button class="btn js-more-events">More events</button>
					`);
                //<button class="btn btn--close-calendar js-close-events u-hidden">Collapse</button> //Not used in DFES
            }
        });
    },

    generate() {
        var startMonth = new Date();
        startMonth.setFullYear(startMonth.getFullYear() - s.yearsBeforeToRender);
        startMonth.setDate(1);

        var currentMonth = startMonth.getMonth(); // current (today) month
        var currentYear = startMonth.getFullYear();

        //Loop through 24 months (year ago, year into future). If we hit 24, gone further than December, so start back at 1 with next year.
        //const monthsToRender = (s.yearsBeforeToRender + s.yearsAheadToRender) * 12; //Years ahead not in use for VH...
        const monthsToRender = s.yearsBeforeToRender * 12 + 13;
        for (var i = 0; i < monthsToRender; i++) {
            if (currentMonth === 12) {
                currentMonth = 0;
                currentYear++;
            }
            this.buildMonth(currentMonth, currentYear);
            currentMonth++;
        }
    },

    buildMonth(currentMonth, currentYear) {
        //Variables to be used later.  Place holders right now.
        var daysHtml = '';
        var totalFeb = '';
        var i = 1;

        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthNamesFull = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday'];

        //Determing if Feb has 28 or 29 days in it.
        if (currentMonth == 1) {
            if ((currentYear % 100 !== 0 && currentYear % 4 === 0) || currentYear % 400 === 0) {
                totalFeb = 29;
            } else {
                totalFeb = 28;
            }
        }
        var totalDays = ['31', '' + totalFeb + '', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31'];

        // Temp values to get the number of days in current month, and previous month. Also getting the day of the week.
        var tempDate = new Date();
        tempDate.setFullYear(currentYear, currentMonth, 1);
        var tempweekday = tempDate.getDay();
        var cmonthTotalDays = parseInt(totalDays[currentMonth]);

        // After getting the first day of the week for the month, padding the other days for that week with the previous months days.  IE, if the first day of the week is on a Thursday, then this fills in Sun - Wed with the last months dates, counting down from the last day on Wed, until Sunday.
        while (tempweekday > 0) {
            daysHtml += "<li class='day day--prevnext'></li>";
            tempweekday--;
        }
        // Filling in the calendar with the current month days in the correct location along.
        while (i <= cmonthTotalDays) {
            const date = `${i}/${currentMonth + 1}/${currentYear}`;

            // checking to see if i is equal to the current day, if so then we are making the color of that cell a different color using CSS. Also adding a rollover effect to highlight the day the user rolls over. This loop creates the actual calendar that is displayed.
            if (i == s.today.getDate() && currentMonth == s.today.getMonth()) {
                daysHtml += `<li class="day currentday" data-calendar-date="${date}"><span class="day__number">${i}</span></li>`;
            } else {
                daysHtml += `<li class="day currentmonth" data-calendar-date="${date}"><span class="day__number">${i}</span></li>`;
            }
            i++;
        }
        //Fill in remaining gaps
        var totalDaysRendered = cmonthTotalDays + tempDate.getDay();
        while (totalDaysRendered % 7 !== 0) {
            daysHtml += "<li class='day day--prevnext'></li>";
            totalDaysRendered++;
        }

        const prevBtnText = currentMonth > 0 ? monthNames[currentMonth - 1] : monthNames[11];
        const nextBtnText = currentMonth < 11 ? monthNames[currentMonth + 1] : monthNames[0];

        // Outputing the calendar onto the site.  Also, putting in the month name and days of the week.
        const calendarMonthClass =
            currentMonth === s.today.getMonth() && currentYear === s.today.getFullYear() ? 'calendar is-active' : 'calendar';
        var calendarMonth = `
				<div class="${calendarMonthClass}">
					<div class="calendar__controls">
						<button class="btnPrev">${prevBtnText}</button>
						<span class="js-current-month">${monthNamesFull[currentMonth]} ${currentYear}</span>
						<button class="btnNext">${nextBtnText}</button>
					</div>
					<div class="calendar__days">
						<ul>
							<li>Sun</li>
							<li>Mon</li>
							<li>Tues</li>
							<li>Wed</li>
							<li>Thurs</li>
							<li>Fri</li>
							<li>Sat</li>
						</ul>
					</div>
					<div class="calendar__dates">
						<ul>
							${daysHtml}
						</ul>
					</div>
				</div>
			`;
        $('.events-calendar')[0].innerHTML += calendarMonth;
    },

    setupInteractions() {
        s.$container = $('[data-' + s.name + ']');

        if (s.$container.length > 0) {
            s.$eventcalendar = $('.calendar');
            s.$btnPrev = $('.btnPrev');
            s.$btnNext = $('.btnNext');

            const $firstCalender = s.$eventcalendar.first();
            $firstCalender.find('.btnPrev').hide();

            s.$eventcalendar.last().find('.btnNext').hide();

            EventsCalendar.Index = 11;

            s.$btnPrev.click((e) => {
                s.$eventcalendar
                    .removeClass('is-active')
                    .eq(EventsCalendar.Index - 1)
                    .addClass('is-active');

                EventsCalendar.Index -= 1;
            });

            s.$btnNext.click((e) => {
                s.$eventcalendar
                    .removeClass('is-active')
                    .eq(EventsCalendar.Index + 1)
                    .addClass('is-active');

                EventsCalendar.Index += 1;
            });

            $(document).on('click', '.js-more-events', function (e) {
                let $activeItem = $('.dates').find('.active');

                if ($activeItem) {
                    $activeItem.parent().removeClass('is-active');
                    $activeItem.removeClass('is-active');
                    $activeItem.prev().show();
                    $('li.is-expanded').remove();
                }

                const $this = $(this);
                const $li = $this.parent();
                const $ul = $li.parent();
                const dateFormatted = $li.find('.day__number').text() + ' ' + $('.calendar.is-active').find('.js-current-month').text();
                $li.addClass('is-active');

                let eventHTMLString = '';

                for (let i = 0; i < $li.find('li').length; i++) {
                    const $event = $li.find('li').eq(i).find('a');

                    eventHTMLString += `
                            <li class="events-listing__item">
                                <a href="${$event.attr('href')}">
                                    <div class="date">
                                        ${dateFormatted}
                                    </div>
                                    <div class="tag">event</div>
                                    <h3 class="title">
                                        ${$event.data('title')}
                                    </h3>
                                    <div class="summary">
                                        <p>${$event.data('description')}</p>
                                    </div>
                                    <div class="listing-arrow"></div>
                                </a>
                            </li>
                        `;
                }

                const index = $ul.children().index($li);
                const rowIndex = Math.ceil(index / 7);

                $ul.children()
                    .eq(rowIndex * 7 - 1)
                    .after(
                        `<li class="is-expanded" style="display: none;">
							<div>
								<button class="btn-close btn-close--big btn-close--lightblue js-close-events">
									<span class="sr-only">Close events panel</span>
								</button>
                                <h2>Events For ${dateFormatted}</h2>
                                <ul class="events-listing">
                                    ${eventHTMLString}
                                </div>
                            </div>
                        </li>`
                    );

                $this.next().focus();

                $('li.is-expanded').addClass('is-active');
                $('li.is-expanded').slideDown();
            });

            $(document).on('click', '.js-close-events', closeCalendar);
            $(document).on('click', function (e) {
                const $target = $(e.target);
                if (!$target.is('.js-more-events') && $('.is-expanded').length && $target.parents('.is-expanded').length === 0) {
                    closeCalendar();
                }
            });

            function closeCalendar() {
                $('li.is-expanded').slideUp(400, function () {
                    $('.day').removeClass('is-active');
                    $('li.is-expanded').remove();
                });
            }
        }
    },
};
